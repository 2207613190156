import wrapPageElement from './config/wrapPageElement';
import wrapRootElement from './config/wrapRootElement';
import { supportedLanguages } from './constants/supportedLanguages';

// const defaultLanguage = 'es';
// const browserLanguage = window.navigator.language.slice(0, 2);

// const isBaseUrl =
//   window.location.pathname === '/' ||
//   window.location.pathname === '/index.html';

// if (supportedLanguages.includes(browserLanguage) && isBaseUrl) {
//   window.location.href = `/${defaultLanguage}/`;
// } else

// if (isBaseUrl) {
//   window.location.href = `/${defaultLanguage}/`;
// }

// gatsby-browser.js
// gatsby-browser.js
export const onRouteUpdate = () => {
    document.querySelectorAll('a[href^="http"]').forEach(link => {
      if (!link.hostname.includes(window.location.hostname)) {
        link.setAttribute('rel', 'noopener noreferrer');
        link.setAttribute('target', '_blank');
      }
    });
  };
  

export { wrapPageElement, wrapRootElement };
