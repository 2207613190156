import i18n from 'i18next';
import { initReactI18next } from 'gatsby-plugin-react-i18next';

let translationEN, translationES;

// Dynamically require translation files based on the environment variable.
// Remember: This is a synchronous operation and assumes the files exist!
// `assets/locales/${process.env.GATSBY_ENV_FILE}/en.json`;
// const envFile = process.env.GATSBY_ENV_FILE || 'defaultClientName';  // replace 'defaultClientName' with a valid client name
translationES = require(`../assets/locales/${process.env.GATSBY_ENV_FILE }/es/es.json`);
  translationEN = require(`../assets/locales/${process.env.GATSBY_ENV_FILE }/en/en.json`);
 


export const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
} as const;

i18n.use(initReactI18next).init({
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
