import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';

const StyledButton = styled.button<{ invertedColors?: boolean }>`
  background-color: ${({ invertedColors, theme }) =>
    invertedColors ? theme.neutralPalette.white : theme.brandPalette.primary};
  border: ${({ invertedColors, theme }) =>
    invertedColors
      ? `1px solid ${theme.neutralPalette.white}`
      : `1px solid ${theme.brandPalette.primary}`};
  color: ${({ invertedColors, theme }) =>
    invertedColors ? theme.brandPalette.primary : theme.neutralPalette.white};

  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: 4px 8px 20px 4px rgba(0, 0, 0, 0.15);
  
  font-size: ${rem(16)};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid
      ${({ invertedColors, theme }) =>
        invertedColors
          ? theme.neutralPalette.white
          : theme.brandPalette.primary};
    color: ${({ invertedColors, theme }) =>
      invertedColors ? theme.neutralPalette.white : theme.brandPalette.primary };
    background-color: transparent;
  }
`;

interface ButtonProps {
  children: React.ReactNode;
  href?: string;
  to?: string;
  invertedColors?: boolean;
  onClick?: () => {};
}

export interface ButtonDTO {
  text: string;
  to?: string;
  onClick?: () => void;
}

const Button = ({ onClick, children, href, invertedColors, to,...props }: ButtonProps): React.JSX.Element => {

  const handleClicToOpenNewURL = (event: React.MouseEvent) => {
    event.preventDefault();

    if (to) {
      window.open(to, '_blank' );
    }
  };

  if (href) {
    return (
      <Link to={href} rel="noopener noreferrer">
        <StyledButton {...props} invertedColors={invertedColors}>
          {children}
        </StyledButton>
      </Link>
    );
  }

  if (to) {
  return (
    <StyledButton onClick={handleClicToOpenNewURL} {...props} invertedColors={invertedColors}>
      {children}
    </StyledButton>
  );
  }

  return (
    <StyledButton onClick={onClick} {...props} invertedColors={invertedColors}>
      {children}
    </StyledButton>
  );
};

export default Button;

export const BlackButton = styled(Button)`
  background-color: ${(props) => props.theme.neutralPalette.neutral9};
  border: 1px solid  ${(props) => props.theme.neutralPalette.neutral9};

&:hover {
  cursor: pointer;
  border: 1px solid  ${(props) => props.theme.neutralPalette.white};
  color:  ${(props) => props.theme.neutralPalette.white};
  background-color: transparent;
}`;

export const AccentButtonWithWhiteHover = styled(Button)`
  background-color: ${(props) => props.theme.brandPalette.accent};
  border: 1px solid  ${(props) => props.theme.brandPalette.accent};

&:hover {
  cursor: pointer;
  border: 1px solid  ${(props) => props.theme.neutralPalette.white};
  color:  ${(props) => props.theme.neutralPalette.white};
  background-color: transparent;
}`;

export const AccentButton = styled(Button)`
  background-color: ${(props) => props.theme.brandPalette.accent};
  border: 1px solid  ${(props) => props.theme.brandPalette.accent};

&:hover {
  cursor: pointer;
  border: 1px solid  ${(props) => props.theme.brandPalette.accent};
  color:  ${(props) => props.theme.brandPalette.accent};
  background-color: transparent;
}`;

export const BlackButtonWithBlackHover = styled(Button)`
  background-color: ${(props) => props.theme.neutralPalette.neutral9};
  border: 1px solid  ${(props) => props.theme.neutralPalette.neutral9};

&:hover {
  cursor: pointer;
  border: 1px solid  ${(props) => props.theme.neutralPalette.neutral9};
  color:  ${(props) => props.theme.neutralPalette.neutral9};
  background-color: transparent;
}`;

export const RoundedButton = styled(Button)<{ invertedColors?: boolean , backgroundColor: string,  textColor:string}>`
background-color: ${({ invertedColors, theme }) =>
  invertedColors ? theme.neutralPalette.white : theme.brandPalette.primary};
border: ${({ invertedColors, theme }) =>
  invertedColors
    ? `1px solid ${theme.neutralPalette.white}`
    : `1px solid ${theme.brandPalette.primary}`};
color: ${({ invertedColors, theme }) =>
  invertedColors ? theme.brandPalette.primary : theme.neutralPalette.white};

padding: 12px 24px;
border-radius: 48px;

font-size: ${rem(20)};
font-weight: ${(props) => props.theme.fontWeights.bold};

transition: all 150ms ease-in-out;

&:hover {
  cursor: pointer;
  border: 1px solid
    ${({ invertedColors, theme }) =>
      invertedColors
        ? theme.neutralPalette.white
        : theme.brandPalette.secondary};
  color: ${({ invertedColors, theme }) =>
    invertedColors ? theme.neutralPalette.white : theme.brandPalette.primary };
  background-color: transparent;
}


  @media (max-width: ${breakpoints.tablet}) {
   font-size: ${rem(18)};
  }

  @media (max-width: ${breakpoints.mobile}) {
   font-size: ${rem(16)};
   height: 42px;
 }

`;

